// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';

function useCustomTranslation() {
  const { t } = useTranslation();

  return t;
}

export { useCustomTranslation as useTranslation };
