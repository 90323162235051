import { changeTheme } from 'design-tokens';
import { getCachedDomainData } from './getCachedDomainData';
import { changeDocumentTitle } from './changeDocumentTitle';
import { changeFavicon } from './changeFavicon';
import i18next from '../i18n/config';

export function restoreCachedDomainData() {
  const domainData = getCachedDomainData();
  if (domainData?.theme) {
    changeTheme(domainData.theme);
  }

  if (domainData?.name) {
    changeDocumentTitle(domainData.name);
  }

  if (domainData?.favicon) {
    changeFavicon(
      domainData.favicon === '' ? '/default.png' : domainData.favicon,
    );
  }

  if (domainData?.language) {
    i18next.changeLanguage(domainData.language);
  }
}
