import { useMutation, useQueryClient } from 'react-query';
import { UpdateSpamProtectionValues, updateSpamProtection } from '../../api';

export function useUpdateSpamProtection() {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: UpdateSpamProtectionValues) => updateSpamProtection(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['emailDetails'] });
      },
      onError: err => {
        console.error('Error updating spam protection ', err);
      },
    },
  );
}
