import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from 'me-component-library';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useGetEmails } from '../../../hooks';
import { useTranslation } from '../../../../_shared/hooks';

interface EmailAddDialogAddressFormProps {
  raiseAddress: (address: string) => void;
  address: string;
}

export const EmailAddDialogAddressForm: React.FC<
  EmailAddDialogAddressFormProps
> = ({ raiseAddress, address }) => {
  const { data: emailData } = useGetEmails({ fromCache: true });
  const t = useTranslation();
  const allowedDomain = useMemo(() => {
    return emailData?.[0]?.email.split('@')[1];
    //TODO this needs a backend endpoint that will provide allowed domains, for now we are assuming that the first email is the allowed domain only
    //eslint-disable-next-line
  }, []);

  const formSchema = z.object({
    email: z
      .string()
      .email({
        message: t('dashboard.emailAddDialogs.errors.invalidEmail'),
      })
      .refine(
        value => {
          const newDomain = value.split('@')[1];
          if (allowedDomain === newDomain) {
            return true;
          }
          return false;
        },
        {
          message: t('dashboard.emailAddDialogs.errors.invalidDomain'),
        },
      )
      .refine(
        value => {
          if (!emailData?.some(e => e.email === value)) {
            return true;
          }
          return false;
        },
        {
          message: t('dashboard.emailAddDialogs.errors.emailAlreadyExists'),
        },
      ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    form.setValue('email', address);
  }, [address, form]);

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    raiseAddress(values.email);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormLabel className="select-none">
          {t('dashboard.emailAddDialogs.form.emailAddressLabel')}
        </FormLabel>
        <div className="flex gap-4">
          <FormField
            control={form.control}
            name="email"
            key="email"
            render={({ field }) => (
              <FormItem className="flex grow flex-col">
                <FormControl>
                  <Input
                    placeholder={t(
                      'dashboard.emailAddDialogs.form.emailAddressLabel',
                    )}
                    data-testid="email-input"
                    className="w-full"
                    type="text"
                    {...field}
                    onBlur={form.handleSubmit(handleSubmit)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
};
