import { useMutation, useQueryClient } from 'react-query';
import {
  UpdateForwardAddressesValues,
  updateForwardAddresses,
} from '../../api';

export function useUpdateForwardAddresses() {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: UpdateForwardAddressesValues) =>
      updateForwardAddresses(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['emailDetails'] });
      },
      onError: err => {
        console.error('Error updating forwardaddresses protection ', err);
      },
    },
  );
}
