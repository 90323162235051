import { api } from './api';

export async function resetPasswordWithToken(
  token: string,
  password: string,
): Promise<boolean> {
  try {
    await api.post('public/reset-password', {
      json: { token, password },
    });
    return true;
  } catch (error) {
    console.error('Error setting password', error);
    return false;
  }
}
