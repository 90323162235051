import { CompanyLogo } from '../../../auth/components';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { Icon } from 'me-component-library';
import { TabStack, TabStackProps } from './TabStack';
import { PATHS } from '../../../_shared/types';
import { useMemo } from 'react';
import { LogoutButton } from '../LogoutButton';

const tabList: TabStackProps[] = [
  {
    categoryTitle: 'dashboard.navbar.categoryLabel.administration',
    tabList: [
      {
        icon: <Icon name="mail" />,
        path: `${PATHS.DASHBOARD}/${PATHS.EMAILS}`,
        tabTitle: 'dashboard.navbar.tabLabel.email',
      },
    ],
  },
];

export const Navbar: React.FC = () => {
  const renderTabStacks = useMemo(
    () =>
      tabList.map(tab => (
        <TabStack {...tab} key={tab.categoryTitle as string} />
      )),
    [],
  );

  return (
    <div className="w-64 self-start -translate-y-32 py-10 px-4 gap-8 flex flex-col items-center border-b-2 bg-background shadow-lg rounded-lg">
      <CompanyLogo />
      <UserAvatar />
      {renderTabStacks}
      <LogoutButton />
    </div>
  );
};
