import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { EmailEditTabBar } from './EmailEditTabBar';
import { AvailableTabs } from '../../types/availabletabs';
import {
  EmailEditTabAutoResponder,
  EmailEditTabForwarding,
  EmailEditTabGeneral,
  EmailEditTabSpam,
  EmailEditTabStorage,
} from './EmailEditTabs';
import { useEmailDetails, useGetEmails } from '../../hooks';
import { LoadingSpinner } from 'me-component-library';
import { EmailDetail } from '../../types';
import { useUser } from '../../../auth/hooks';
import { useTranslation } from '../../../_shared/hooks';

type EmailEditDetailsProps = {
  emailId: string;
};

type RenderTabProps = {
  tab: AvailableTabs;
  data: EmailDetail;
  hasMailbox: boolean;
  isAdmin: boolean;
};

const renderTab = ({ tab, data, hasMailbox, isAdmin }: RenderTabProps) => {
  switch (tab) {
    case AvailableTabs.GENERAL:
      return (
        <EmailEditTabGeneral
          isAdmin={isAdmin}
          data={data}
          hasMailbox={hasMailbox}
        />
      );
    case AvailableTabs.STORAGE:
      return <EmailEditTabStorage data={data} />;
    case AvailableTabs.SPAM:
      return <EmailEditTabSpam data={data} />;
    case AvailableTabs.FORWARDS:
      return <EmailEditTabForwarding data={data} />;
    case AvailableTabs.AUTORESPONDER:
      return <EmailEditTabAutoResponder data={data} />;
    default:
      return (
        <EmailEditTabGeneral
          isAdmin={isAdmin}
          data={data}
          hasMailbox={hasMailbox}
        />
      );
  }
};

export const EmailEditDetails: React.FC<EmailEditDetailsProps> = ({
  emailId,
}: EmailEditDetailsProps) => {
  const [activeTab, setActiveTab] = useState(AvailableTabs.GENERAL);
  const {
    isLoading,
    data: emailDetailsData,
    isError,
  } = useEmailDetails(emailId);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { data: userData } = useUser();
  const { data: emailData } = useGetEmails({ fromCache: true });
  const t = useTranslation();

  const getHasMailboxIfStateAbsent = () => {
    return emailData?.find(email => email.id === emailId)?.hasMailbox ?? false;
  };

  const hasMailbox = useMemo(
    () => location.state?.hasMailbox ?? getHasMailboxIfStateAbsent(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emailId],
  );

  const isAdmin = useMemo(
    () => userData?.roles.includes('EMAIL_ADMIN') ?? false,
    [userData?.roles],
  );

  const handleTabChange = useCallback((tab: AvailableTabs) => {
    setSearchParams(`tab=${tab.toString()}`);
    setActiveTab(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab as AvailableTabs);
    }
  }, [searchParams, setSearchParams]);

  if (isError || (emailDetailsData === undefined && isLoading === false)) {
    return <div>{t('dashboard.emailDetails.details.errors.loading')}</div>;
  }

  return (
    <>
      <EmailEditTabBar
        activeTab={activeTab}
        onTabClick={handleTabChange}
        hasMailbox={hasMailbox}
        isAdmin={isAdmin}
      />
      <div className="w-full bg-white px-8 py-12 shadow-lg">
        <div>
          {isLoading === true ? (
            <div className="flex justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            renderTab({
              tab: activeTab,
              data: emailDetailsData,
              hasMailbox,
              isAdmin,
            })
          )}
        </div>
      </div>
    </>
  );
};
