import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Button,
  Card,
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useToast,
  Icon,
} from 'me-component-library';
import { useRequestPasswordReset } from '../../hooks';
import i18next from 'i18next';
import { useTranslation } from '../../../_shared/hooks';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../_shared/types';

const formSchema = z.object({
  email: z.string().email(),
});

export function ForgotPasswordForm() {
  const { mutate, isLoading } = useRequestPasswordReset();
  const { toast } = useToast();
  const navigate = useNavigate();
  const t = useTranslation();

  const toggleErrorToast = () => {
    toast({
      title: t('auth.forgotPassword.toasts.error.title'),
      description: t('auth.forgotPassword.toasts.error.message'),
      variant: 'error',
      duration: 5000,
    });
  };

  const toggleSuccessToast = () => {
    toast({
      title: t('auth.forgotPassword.toasts.success.title'),
      description: t('auth.forgotPassword.toasts.success.message'),
      variant: 'success',
      duration: 5000,
    });
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutate(
      {
        email: values.email,
        language: i18next.language,
      },
      { onSuccess: toggleSuccessToast, onError: toggleErrorToast },
    );
  }

  return (
    <div className="flex justify-center items-center">
      <Card className="w-96 shadow-xl py-12 bg-white px-8 rounded-md">
        <h1 className="text-2xl mb-1">
          <Icon
            name="chevronLeft"
            className="cursor-pointer my-1"
            size="40"
            onClick={() => navigate('/' + PATHS.LOGIN + '/')}
          />
          {t('auth.forgotPassword.title')}
        </h1>
        <p className="text-s mb-8">{t('auth.forgotPassword.subtitle')}</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="email"
              key="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('auth.login.emailAddressLabel')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t('auth.login.emailAddressPlaceholder')}
                      data-testid="username-input"
                      type="text"
                      autoComplete="username"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              isLoading={isLoading}
              disabled={!form.formState.isValid || isLoading}
              data-testid="submit-button"
            >
              {t('auth.setPassword.submitButton')}
            </Button>
          </form>
        </Form>
      </Card>
    </div>
  );
}
