import { useMemo } from 'react';
import { useTranslation } from '../../../_shared/hooks';
import { useUser } from '../../../auth/hooks';
import { EmailAddButtons, EmailTable } from '../../components';

export const Email: React.FC = () => {
  const t = useTranslation();
  const { data: userData, isLoading } = useUser();

  const isAdmin = useMemo(() => {
    if (isLoading === false && userData !== undefined) {
      return userData?.roles?.includes('EMAIL_ADMIN') ?? false;
    }
    return false;
  }, [isLoading, userData]);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl select-none">
          {t('dashboard.emailTable.title')}
        </h1>
        {isAdmin && <EmailAddButtons />}
      </div>
      <div className="w-full bg-white px-8 py-12 mt-8 rounded-md shadow-lg">
        <EmailTable />
      </div>
    </div>
  );
};
