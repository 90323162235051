import { api } from './api';

export type UpdateStorageValues = {
  emailId: string;
  quotaInBytes: number;
};

export async function updateStorageQuota({
  emailId,
  quotaInBytes,
}: UpdateStorageValues) {
  const response = await api.patch(`emails/${emailId}/quota`, {
    json: {
      quotaInBytes,
    },
  });
  return response.json();
}
