import { api } from './api';

export type UpdateAutoResponderValues = {
  emailId: string;
  active: boolean;
  expiresAt?: string;
  message: string;
  startsAt?: string;
};

export async function updateAutoResponder({
  emailId,
  ...values
}: UpdateAutoResponderValues) {
  const response = await api.patch(`emails/${emailId}/autoresponder`, {
    json: {
      ...values,
    },
  });
  return response.json();
}
