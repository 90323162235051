import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Button,
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  useToast,
  FormMessage,
} from 'me-component-library';
import { useTranslation } from '../../hooks';
import { PasswordStrengthDisplay } from './PasswordStrengthDisplay';
import { useState } from 'react';
import { generateRandomPassword } from '../../../dashboard/util';

const passwordMinimumLength = 12;

const passwordFormSchema = z
  .object({
    password: z
      .string()
      .min(passwordMinimumLength)
      .refine(value => /\d/.test(value))
      .refine(value => /\W|_/.test(value)),
    repeatPassword: z.string().min(passwordMinimumLength),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.repeatPassword) {
      ctx.addIssue({
        code: 'custom',
      });
    }
  });

type PasswordFormProps = {
  onSubmitAction: (id: string, password: string) => Promise<boolean>;
  onSubmitSuccess: () => void;
  passwordId: string;
  hideConfirmPassword?: boolean;
  hideStrengthDisplay?: boolean;
  hideSubmitButton?: boolean;
  raiseAddress?: (password: string) => void;
};

export function PasswordForm({
  onSubmitSuccess,
  onSubmitAction,
  passwordId,
  hideConfirmPassword,
  hideStrengthDisplay,
  hideSubmitButton,
  raiseAddress,
}: PasswordFormProps) {
  const t = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof passwordFormSchema>>({
    resolver: zodResolver(passwordFormSchema),
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    reValidateMode: 'onBlur',
  });

  const handleBlur = (values: z.infer<typeof passwordFormSchema>) => {
    if(raiseAddress){
      raiseAddress(values.password);
    }
  };

  async function onSubmit(values: z.infer<typeof passwordFormSchema>) {
    let res;
    try {
      setIsLoading(true);
      res = await onSubmitAction(passwordId, values.password);
    } catch (e) {
      console.error(e);
      toast({
        title: t('auth.setPassword.errors.toast.title'),
        description: t('auth.setPassword.errors.toast.description'),
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }

    if (res === true) {
      onSubmitSuccess();
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={hideConfirmPassword ? 'space-y-0' : 'space-y-8'}
      >
        <Input
          className="hidden"
          name="username"
          hidden={true}
          autoComplete="username"
        />
        <FormField
          control={form.control}
          name="password"
          key="password"
          render={({ field }) => (
            <FormItem
              className={hideConfirmPassword ? 'space-y-0' : 'space-y-2'}
            >
              <FormLabel>{t('auth.setPassword.passwordLabel')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('auth.setPassword.passwordPlaceholder')}
                  data-testid="password-input"
                  autoComplete="new-password"
                  type="password"
                  showEyeIcon={true}
                  showGeneratePasswordIcon={true}
                  showGeneratePasswordCallback={() => {
                    form.setValue('password', generateRandomPassword());
                    handleBlur(form.getValues());
                  }}
                  {...field}
                  onBlur={() => {
                    handleBlur(form.getValues());
                  }}
                />
              </FormControl>
              {hideConfirmPassword && (
                <div className={hideConfirmPassword ? '!mt-2' : ''}>
                  <FormMessage />
                </div>
              )}
            </FormItem>
          )}
        />
        {!hideConfirmPassword && (
          <FormField
            control={form.control}
            name="repeatPassword"
            key="repeatPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('auth.setPassword.confirmPasswordLabel')}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t(
                      'auth.setPassword.confirmPasswordPlaceholder',
                    )}
                    data-testid="password-input"
                    autoComplete="new-password"
                    type="password"
                    showEyeIcon={true}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        )}
        {!hideStrengthDisplay && (
          <PasswordStrengthDisplay
            passwordsMatch={
              form.watch().password.length > 0 &&
              form.getValues().password === form.getValues().repeatPassword
            }
            containsSpecialCharacter={/\W|_/.test(form.watch().password)}
            containsNumber={/\d/.test(form.watch().password)}
            minimumLengthMet={
              form.watch().password.length >= passwordMinimumLength
            }
          />
        )}
        {!hideSubmitButton && (
          <Button
            type="submit"
            className="w-full"
            isLoading={isLoading}
            disabled={!form.formState.isValid || isLoading}
            data-testid="submit-button"
          >
            {t('auth.setPassword.submitButton')}
          </Button>
        )}
      </form>
    </Form>
  );
}
