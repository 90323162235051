import { api } from './api';

export type UpdateForwardAddressesValues = {
  emailId: string;
  forwardAddresses: string[];
};

export async function updateForwardAddresses({
  emailId,
  forwardAddresses,
}: UpdateForwardAddressesValues) {
  const response = await api.patch(`emails/${emailId}/forwardaddress`, {
    json: {
      forwardAddresses,
    },
  });
  return response.json();
}
