import { Skeleton } from 'me-component-library';

export const EmailListSkeleton: React.FC = () => {
  return (
    <div
      className="w-full flex flex-col gap-4"
      data-testid="email-list-skeleton"
    >
      <Skeleton className="w-1/3 h-6" />
      <Skeleton className="w-full h-12" />
      <Skeleton className="w-full h-12" />
      <Skeleton className="w-full h-12" />
      <Skeleton className="w-full h-12" />
    </div>
  );
};
