import { useMutation, useQueryClient } from 'react-query';
import {
  UpdateStorageValues,
  updateStorageQuota,
} from '../../api/updateStorageQuota';

export function useUpdateQuota() {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: UpdateStorageValues) => updateStorageQuota(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['emailDetails'] });
      },
      onError: err => {
        console.error('Error updating quota ', err);
      },
    },
  );
}
