import { useToast } from 'me-component-library';
import { EmailDetail } from '../../../../types';
import { ToggleSwitch, UpdateOrCancelButtons } from '../../../shared';
import { LoadingOverlay } from '../../../shared/LoadingOverlay';
import { useUpdateAutoResponder } from '../../../../hooks';
import { UpdateAutoResponderValues } from '../../../../api/updateAutoResponder';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EmailEditTabAutoResponderBadge } from './EmailEditTabAutoResponderBadge';
import { EmailEditTabAutoResponderTimeFrame } from './EmailEditTabAutoResponderTimeFrame';
import { EmailEditTabAutoResponderMessageForm } from './EmailEditTabAutoResponderMessageForm';
import { useTranslation } from '../../../../../_shared/hooks';

interface EmailEditTabAutoResponderProps {
  data: EmailDetail;
}

export const EmailEditTabAutoResponder: React.FC<
  EmailEditTabAutoResponderProps
> = ({ data }) => {
  const timeFrameshouldBeChecked = useMemo(() => {
    return data.autoResponder.expiresAt !== undefined;
  }, [data.autoResponder.expiresAt]);

  const { mutate, isLoading } = useUpdateAutoResponder();
  const { toast } = useToast();
  const t = useTranslation();
  const [showUpdateButtons, setShowUpdateButtons] = useState(false);
  const [timeFrameChecked, setTimeFrameChecked] = useState(
    timeFrameshouldBeChecked,
  );
  const [cachedEmailData, setCachedEmailData] = useState<EmailDetail>(data);
  const updateButtonRef = useRef<HTMLDivElement>(null);

  const toggleErrorToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.shared.toasts.error.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.error.message',
      ),
      variant: 'error',
      duration: 3000,
    });
  };

  const toggleSuccessToast = () => {
    toast({
      title: t(
        'dashboard.emailEditTabs.tabs.autoresponder.toasts.success.title',
      ),
      description: t(
        'dashboard.emailEditTabs.tabs.autoresponder.toasts.success.message',
      ),
      variant: 'success',
      duration: 3000,
    });
  };

  const handleMutation = useCallback(
    (newData: Partial<UpdateAutoResponderValues>) => {
      setCachedEmailData({
        ...cachedEmailData,
        autoResponder: {
          ...cachedEmailData.autoResponder,
          ...newData,
        },
      });
    },
    [cachedEmailData],
  );

  const handleCancel = useCallback(() => {
    setCachedEmailData(data);
    setTimeFrameChecked(timeFrameshouldBeChecked);
  }, [data, timeFrameshouldBeChecked]);

  const performMutation = useCallback(() => {
    mutate(
      {
        emailId: data.id,
        ...cachedEmailData.autoResponder,
      },
      {
        onSuccess: () => {
          toggleSuccessToast();
        },
        onError: () => {
          toggleErrorToast();
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedEmailData, data.id, mutate]);

  const handleCheckBoxChange = useCallback(
    (enabled: boolean) => {
      handleMutation({
        active: enabled,
      });
    },
    [handleMutation],
  );

  const handleTimeFrameChecked = useCallback(
    (newValue: boolean) => {
      setTimeFrameChecked(newValue);
      if (newValue === false) {
        handleMutation({
          expiresAt: undefined,
          startsAt: undefined,
        });
      }
    },
    [handleMutation],
  );

  useEffect(() => {
    if (JSON.stringify(cachedEmailData) !== JSON.stringify(data)) {
      setShowUpdateButtons(true);
    } else {
      setShowUpdateButtons(false);
    }
  }, [cachedEmailData, data, setShowUpdateButtons]);

  useEffect(() => {
    setCachedEmailData(data);
  }, [data]);

  useEffect(() => {
    if (showUpdateButtons && updateButtonRef.current)
      updateButtonRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [showUpdateButtons]);

  if (data.isCatchAll === true) {
    return t(
      'dashboard.emailEditTabs.tabs.autoresponder.errors.catchAllActive',
    );
  }

  return (
    <div className="relative">
      {isLoading && <LoadingOverlay />}
      <div className="flex justify-between items-center">
        <div className="text-xl">
          {t('dashboard.emailEditTabs.tabs.autoresponder.title')}
        </div>
        <div className="h-4">
          <ToggleSwitch
            enabled={cachedEmailData.autoResponder.active}
            onChange={handleCheckBoxChange}
            inactiveLabel={t(
              'dashboard.emailEditTabs.tabs.shared.toggleSwitch.inactive',
            )}
            activeLabel={t(
              'dashboard.emailEditTabs.tabs.shared.toggleSwitch.active',
            )}
          />
        </div>
      </div>
      <div className="w-5/6 mt-3 text-sm">
        {t('dashboard.emailEditTabs.tabs.autoresponder.description')}
      </div>
      {cachedEmailData.autoResponder.active && (
        <>
          <EmailEditTabAutoResponderBadge
            expiresAt={cachedEmailData.autoResponder.expiresAt}
            timeFrameChecked={timeFrameChecked}
          />
          <hr className="my-9" />
          <EmailEditTabAutoResponderTimeFrame
            raiseMutation={handleMutation}
            timeFrameChecked={timeFrameChecked}
            raiseTimeFrameChecked={handleTimeFrameChecked}
            expiresAt={cachedEmailData.autoResponder.expiresAt}
            startsAt={cachedEmailData.autoResponder.startsAt}
          />
          <hr className="my-9" />
          <EmailEditTabAutoResponderMessageForm
            message={cachedEmailData.autoResponder.message}
            raiseMutation={handleMutation}
          />
        </>
      )}
      {showUpdateButtons && (
        <div>
          <hr className="my-9" />
          <div className="flex justify-end">
            <UpdateOrCancelButtons
              ref={updateButtonRef}
              onUpdate={performMutation}
              onCancel={() => handleCancel()}
            />
          </div>
        </div>
      )}
    </div>
  );
};

//TODO refactor, this logic is quite similar to EmailEditTabSpam
