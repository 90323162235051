import { HTTPError } from 'ky';
import { api } from './api';

export enum PasswordTokenStatus {
  VALID,
  INVALID,
  EXPIRED,
}

export async function getIsPasswordTokenValid(
  token: string,
): Promise<PasswordTokenStatus> {
  try {
    const response = await api.get(
      `public/validate-password-token?token=${token}`,
    );
    if (response.status === 200) {
      return PasswordTokenStatus.VALID;
    }
  } catch (error) {
    const asHttpError = error as HTTPError;
    const asJson = await asHttpError.response.json();

    if (asJson.message === 'Invalid token') {
      return PasswordTokenStatus.INVALID;
    }
  }
  return PasswordTokenStatus.EXPIRED;
}

export async function setPasswordWithToken(
  token: string,
  password: string,
): Promise<boolean> {
  try {
    await api.post('public/init-password', {
      json: { token, password },
    });
    return true;
  } catch (error) {
    console.error('Error setting password', error);
    return false;
  }
}
