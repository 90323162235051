import { SetPasswordForm } from '../../components';
import { PasswordTokenStatus } from '../../api/password';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'me-component-library';
import { useTranslation } from '../../../_shared/hooks';
import { useIsPasswordTokenValid } from '../../hooks';
import { BasePage } from '../BasePage';

export const SetPassword: React.FC = () => {
  const params = useParams();
  const t = useTranslation();
  const { data, isLoading } = useIsPasswordTokenValid(params.token ?? '');
  const { pathname } = useLocation();
  const isResetPassword = pathname.includes('reset-password');

  if (isLoading === true) {
    return (
      <>
        <div className="w-full bg-gray-200 h-screen flex items-center justify-center">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <BasePage>
      {data === PasswordTokenStatus.VALID ? (
        <SetPasswordForm isResetPassword={isResetPassword} />
      ) : data === PasswordTokenStatus.EXPIRED ? (
        <div className="mt-56">{t('auth.setPassword.tokenStatus.expired')}</div>
      ) : (
        <div className="mt-56">{t('auth.setPassword.tokenStatus.invalid')}</div>
      )}
    </BasePage>
  );
};
