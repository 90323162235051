import { ReactNode } from 'react';
import { useTranslation } from '../../hooks';

type PasswordStrengthDisplayProps = {
  passwordsMatch: boolean;
  containsSpecialCharacter: boolean;
  containsNumber: boolean;
  minimumLengthMet: boolean;
};

type DotProps = {
  condition: boolean;
  children: ReactNode;
};

function Dot({ condition, children }: DotProps) {
  return condition ? (
    <div>
      <span className="mr-3 text-green-500">●</span>
      {children}
    </div>
  ) : (
    <div>
      <span className="mr-3 text-red-500">●</span>
      {children}
    </div>
  );
}

export function PasswordStrengthDisplay({
  passwordsMatch,
  containsSpecialCharacter,
  containsNumber,
  minimumLengthMet,
}: PasswordStrengthDisplayProps) {
  const t = useTranslation();

  return (
    <>
      <Dot condition={passwordsMatch}>
        {t('auth.setPassword.passwordStrength.passwordsMatch')}
      </Dot>
      <Dot condition={containsSpecialCharacter}>
        {t('auth.setPassword.passwordStrength.containsSpecialCharacter')}
      </Dot>
      <Dot condition={containsNumber}>
        {t('auth.setPassword.passwordStrength.containsNumber')}
      </Dot>
      <Dot condition={minimumLengthMet}>
        {t('auth.setPassword.passwordStrength.minimumLengthMet')}
      </Dot>
    </>
  );
}
