import { useToast } from 'me-component-library';
import { useUpdateSpamProtection } from '../../../../hooks/useUpdateSpamProtection';
import { EmailDetail } from '../../../../types';
import { ToggleSwitch } from 'me-component-library/src/components/ToggleSwitch';
import './EmailEditTabSpam.scss';
import { EmailEditTabSpamSensivity } from './EmailEditTabSpamSensivity';
import { UpdateSpamProtectionValues } from '../../../../api';
import { EmailEditTabSpamAutoDelete } from './EmailEditTabSpamAutoDelete';
import { LoadingOverlay } from '../../../shared/LoadingOverlay';
import { EmailEditTabSpamSorting } from './EmailEditTabSpamSorting';
import { useEffect, useRef, useState } from 'react';
import { UpdateOrCancelButtons } from '../../../shared';
import { useTranslation } from '../../../../../_shared/hooks';

interface EmailEditTabSpamProps {
  data: EmailDetail;
}

export const EmailEditTabSpam: React.FC<EmailEditTabSpamProps> = ({ data }) => {
  const { mutate, isLoading } = useUpdateSpamProtection();
  const { toast } = useToast();
  const [showUpdateButtons, setShowUpdateButtons] = useState(false);
  const [cachedEmailData, setCachedEmailData] = useState<EmailDetail>(data);
  const updateButtonRef = useRef<HTMLDivElement>(null);
  const t = useTranslation();

  const toggleErrorToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.shared.toasts.error.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.error.message',
      ),
      variant: 'error',
      duration: 3000,
    });
  };

  const toggleSuccessToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.spam.toasts.success.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.spam.toasts.success.message',
      ),
      variant: 'success',
      duration: 3000,
    });
  };

  const handleMutation = (newData: Partial<UpdateSpamProtectionValues>) => {
    setCachedEmailData({
      ...cachedEmailData,
      mailbox: {
        ...cachedEmailData.mailbox,
        spamProtection: {
          ...cachedEmailData.mailbox.spamProtection,
          ...newData,
        },
      },
    });
  };

  useEffect(() => {
    if (JSON.stringify(cachedEmailData) !== JSON.stringify(data)) {
      setShowUpdateButtons(true);
    } else {
      setShowUpdateButtons(false);
    }
  }, [cachedEmailData, data, setShowUpdateButtons]);

  useEffect(() => {
    setCachedEmailData(data);
  }, [data]);

  useEffect(() => {
    if (showUpdateButtons && updateButtonRef.current)
      updateButtonRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [showUpdateButtons]);

  const performMutation = () => {
    mutate(
      {
        emailId: data.id,
        ...cachedEmailData.mailbox.spamProtection,
      },
      {
        onSuccess: () => {
          toggleSuccessToast();
        },
        onError: () => {
          toggleErrorToast();
        },
      },
    );
  };

  const handleCheckBoxChange = (enabled: boolean) => {
    handleMutation({
      active: enabled,
    });
  };

  return (
    <div className="relative">
      {isLoading && <LoadingOverlay />}
      <div className="flex justify-between items-center">
        <div className="text-xl">
          {t('dashboard.emailEditTabs.tabs.spam.title')}
        </div>
        <div className="h-4">
          <ToggleSwitch
            enabled={cachedEmailData.mailbox.spamProtection.active}
            onChange={handleCheckBoxChange}
            inactiveLabel={t(
              'dashboard.emailEditTabs.tabs.shared.toggleSwitch.inactive',
            )}
            activeLabel={t(
              'dashboard.emailEditTabs.tabs.shared.toggleSwitch.active',
            )}
          />
        </div>
      </div>
      <div className="w-5/6 mt-2 text-sm">
        {t('dashboard.emailEditTabs.tabs.spam.description')}
      </div>
      {cachedEmailData.mailbox.spamProtection.active && (
        <>
          <hr className="my-9" />
          <EmailEditTabSpamSensivity
            raiseMutation={handleMutation}
            data={cachedEmailData}
          />
          <hr className="my-9" />
          <EmailEditTabSpamAutoDelete
            data={cachedEmailData}
            raiseMutation={handleMutation}
          />
          <hr className="my-9" />
          <EmailEditTabSpamSorting
            data={cachedEmailData}
            raiseMutation={handleMutation}
          />
        </>
      )}
      {showUpdateButtons && (
        <div>
          <hr className="my-9" />
          <div className="flex justify-end">
            <UpdateOrCancelButtons
              ref={updateButtonRef}
              onUpdate={performMutation}
              onCancel={() => setCachedEmailData(data)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
