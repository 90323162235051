import React from 'react';
import { useTranslation } from '../../../../_shared/hooks';

export type UpdateOrCancelButtonsProps = {
  onCancel: () => void;
  onUpdate: () => void;
};

export const UpdateOrCancelButtons = React.forwardRef<
  HTMLDivElement,
  UpdateOrCancelButtonsProps
>(({ onCancel, onUpdate }, ref) => {
  const t = useTranslation();
  return (
    <div ref={ref} className="flex mt-5 gap-8">
      <button
        onClick={onUpdate}
        className="bg-green-500 rounded-md p-3 text-white hover:bg-slate-500"
      >
        {t('dashboard.sharedComponents.updateOrCancelButtons.update')}
      </button>
      <button
        onClick={onCancel}
        className="bg-red-500 rounded-md p-3 text-white hover:bg-slate-500"
      >
        {t('dashboard.sharedComponents.updateOrCancelButtons.cancel')}
      </button>
    </div>
  );
});
