import { useTranslation } from '../../hooks';

export const NotFound = () => {
  const t = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center">
      <h1 className="text-6xl font-bold text-red-500">404</h1>
      <p className="mt-4 text-lg text-gray-600">{t('notFound.notFound')}</p>
    </div>
  );
};
