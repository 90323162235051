import { useMutation, useQueryClient } from 'react-query';
import { logout } from '../../api/logout';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../_shared/types';

export function useLogout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(logout, {
    onSuccess: () => {
      localStorage.removeItem('accessToken');
      queryClient.clear();
      navigate('/' + PATHS.LOGIN);
    },
  });
}
