import { useMutation, useQueryClient } from 'react-query';
import { deleteEmail } from '../../api/deleteEmail';

export function useDeleteEmail() {
  const queryClient = useQueryClient();
  return useMutation((variables: string) => deleteEmail(variables), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emails'] });
    },
    onError: err => {
      console.error('Error deleting email', err);
    },
  });
}
