import ky from 'ky';
import { PATHS } from '../../_shared/types';

const endpointPrefix = import.meta.env.VITE_API_URL || '';

export const api = ky.extend({
  prefixUrl: `${endpointPrefix}/api/v1`,
  hooks: {
    beforeRequest: [
      request => {
        const accessToken = localStorage.getItem('accessToken');
        request.headers.set('Authorization', `Bearer ${accessToken}`);
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status === 498 || response.status === 401) {
          localStorage.removeItem('accessToken');
          //push to /login
          window.location.replace('/' + PATHS.LOGIN);
        }
      },
    ],
  },
});
