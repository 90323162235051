import { PropsWithChildren } from 'react';
import './EmailEditTabSpamButton.scss';

interface Props extends PropsWithChildren {
  onClick: () => void;
}

export const EmailEditTabSpamButton: React.FC<Props> = ({
  children,
  onClick,
}) => {
  return (
    <div className="email-edit-tab-spam-button" onClick={onClick}>
      {children}
    </div>
  );
};
