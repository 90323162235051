import { Card } from 'me-component-library';
import { useTranslation } from '../../../_shared/hooks';
import { PasswordForm } from '../../../_shared/components/PasswordForm';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../../_shared/types';
import { resetPasswordWithToken, setPasswordWithToken } from '../../api/';

export type SetPasswordFormProps = {
  isResetPassword: boolean;
};

export function SetPasswordForm({ isResetPassword }: SetPasswordFormProps) {
  const t = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="flex justify-center items-center">
      <Card className="w-96 shadow-xl py-12 bg-white px-8 rounded-md">
        <h1 className="text-2xl mb-1">{t('auth.setPassword.title')}</h1>
        <p className="text-s mb-8">{t('auth.setPassword.subtitle')}</p>
        <PasswordForm
          onSubmitAction={
            isResetPassword ? resetPasswordWithToken : setPasswordWithToken
          }
          onSubmitSuccess={() => navigate(`/${PATHS.LOGIN}`)}
          passwordId={params.token as string}
        />
      </Card>
    </div>
  );
}
