var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { cn } from '../../utils';
const Alert = React.forwardRef((_a, ref) => {
    var { className, variant } = _a, props = __rest(_a, ["className", "variant"]);
    const selectedVariant = alertVariants[variant || 'default'];
    return (_jsx("div", Object.assign({ ref: ref, role: "alert", className: cn('relative w-full rounded-lg border border-primary/20 p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-primary/95', selectedVariant, className) }, props)));
});
Alert.displayName = 'Alert';
const AlertTitle = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("h5", Object.assign({ ref: ref, className: cn('mb-1 font-medium leading-none tracking-tight', className) }, props)));
});
AlertTitle.displayName = 'AlertTitle';
const AlertDescription = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ ref: ref, className: cn('text-sm [&_p]:leading-relaxed', className) }, props)));
});
AlertDescription.displayName = 'AlertDescription';
export { Alert, AlertTitle, AlertDescription };
const alertVariants = {
    default: 'bg-white text-secondary-foreground',
    destructive: 'border-red-500/50 text-red-500',
};
