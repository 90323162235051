import { auth } from '../../../auth/i18n/de';
import { dashboard } from '../../../dashboard/i18n/de';
import footer from './assets/footer.json';
import notFound from './assets/notfound.json';

export const de = {
  translation: {
    auth,
    dashboard,
    footer,
    notFound,
  },
};
