import { useCallback, useMemo } from 'react';
import { useGetCustomDomainData } from '../../../_shared/hooks/useGetCustomDomainData/useGetCustomDomainData';
import { CustomDomainData } from '../../../_shared/types';
import { useTranslation } from '../../../_shared/hooks';

export const LoginCover: React.FC = () => {
  const { data } = useGetCustomDomainData();
  const t = useTranslation();
  const defaultLoginImg = 'default-login-cover.png';

  const renderLoginImage = useCallback((domaiData?: CustomDomainData) => {
    if (domaiData === null || domaiData === undefined) {
      return defaultLoginImg;
    } else if (domaiData.imgLogin.length === 0) {
      return defaultLoginImg;
    }

    return domaiData?.imgLogin;
  }, []);

  const renderedLoginImage = useMemo(
    () => renderLoginImage(data),
    [data, renderLoginImage],
  );

  const isDefaultLoginImage = renderedLoginImage === defaultLoginImg;

  return (
    <div
      style={{
        backgroundImage: `url("${renderedLoginImage}")`,
      }}
      className={
        isDefaultLoginImage
          ? 'w-full md:w-1/2 flex justify-center items-center p-4 right-col bg-black bg-contain bg-no-repeat bg-center relative'
          : 'w-full md:w-1/2 flex justify-center items-center p-4 right-col bg-contain bg-no-repeat bg-center relative bg-zinc-100'
      }
    >
      {isDefaultLoginImage && (
        <div className="select-none">
          <div className="absolute inset-0 bg-gray-500 opacity-50 rounded-md mix-blend-multiply" />
          <div className="flex flex-col rounded-xl text-center max-w-80 min-h-[540px] p-6 bg-gradient-to-b from-gray-500/15 to-gray-500/5 backdrop-blur-lg shadow-2xl shadow-gray-500/50 border-2 border-gray-500/15">
            <div className="flex justify-center">
              <img src="/default-logo.png" height="48" width="48" />
            </div>
            <h3 className="text-left text-2xl font-extrabold text-white tracking-wide">
              Postmaster
            </h3>
            <p className="mt-4 text-left text-neutral-200">
              {t('auth.login.cover.paragraph')}
            </p>
            <ol className="list-decimal list-inside mt-4 text-left text-white leading-loose">
              <li className="text-neutral-200">
                {t('auth.login.cover.usps.usp_1')}
              </li>
              <li className="text-neutral-200">
                {t('auth.login.cover.usps.usp_2')}
              </li>
              <li className="text-neutral-200">
                {t('auth.login.cover.usps.usp_3')}
              </li>
            </ol>
            <p className="text-left text-neutral-400 mt-2 uppercase text-xs tracking-wide">
              powered by weissaufschwarz
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

//TODO should this even be allowed to be themeable? People won't want their custom images to be tinted
