import { useMutation } from 'react-query';
import { getUserToken } from '../../api';
import { LoginCredentials } from '../../types';

type useGetTokenOptions = {
  onSuccess?: () => void;
  onError?: () => void;
};

export function useGetToken(options?: useGetTokenOptions) {
  return useMutation((variables: LoginCredentials) => getUserToken(variables), {
    onSuccess: data => {
      localStorage.setItem('accessToken', data.accessToken);
      options?.onSuccess?.();
    },
    onError: () => {
      options?.onError?.();
    },
  });
}
