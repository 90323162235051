import { HTTPError } from 'ky';
import { CustomDomainData } from '../types';
import { api } from '../../auth/api/api';
import { changeDocumentTitle, changeFavicon } from '../utils';

export async function getCustomDomainData(): Promise<
  CustomDomainData | undefined
> {
  try {
    const selectedDomain = window.location.host;
    if (
      selectedDomain.includes('postmaster.digital') ||
      selectedDomain.includes('.localhost')
    ) {
      // Don't fetch custom domain data for these domains
      changeDocumentTitle('postmaster');
      changeFavicon('/default.png');
      return;
    }

    const response = await api.get(
      `public/custom-data?domain=${selectedDomain}`,
    );
    return response.json();
  } catch (error) {
    const httpError = error as HTTPError;
    if (
      httpError.message.includes('404') ||
      httpError.message.includes('500') ||
      httpError.message.includes('502')
    ) {
      changeDocumentTitle('postmaster');
      changeFavicon('/default.png');
    }
    console.log('No custom domain data available', error);
  }
}
