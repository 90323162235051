import { RouteObject } from 'react-router-dom';
import { Home } from './_shared/pages';
import { ForgotPassword, Login, SetPassword } from './auth/pages';
import { NotFound, PrivateRoutes } from './_shared/components';
import { PATHS } from './_shared/types';
import { DashboardLayout } from './dashboard/components';
import { Email, EmailDetail } from './dashboard/pages';
import { WithFooterWrapper } from './auth/components';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: PATHS.LOGIN,
    element: (
      <WithFooterWrapper>
        <Login />
      </WithFooterWrapper>
    ),
  },
  {
    path: PATHS.SET_PASSWORD + '/' + PATHS.SET_PASSWORD_TOKEN,
    element: (
      <WithFooterWrapper>
        <SetPassword />
      </WithFooterWrapper>
    ),
  },
  {
    path: PATHS.RESET_PASSWORD + '/' + PATHS.SET_PASSWORD_TOKEN,
    element: (
      <WithFooterWrapper>
        <SetPassword />
      </WithFooterWrapper>
    ),
  },
  {
    path: PATHS.FORGOT_PASSWORD,
    element: (
      <WithFooterWrapper>
        <ForgotPassword />
      </WithFooterWrapper>
    ),
  },
  {
    path: PATHS.DASHBOARD,
    element: (
      <PrivateRoutes>
        <DashboardLayout />
      </PrivateRoutes>
    ),
    children: [
      {
        path: PATHS.EMAILS,
        element: <Email />,
      },
      {
        path: PATHS.EMAILS + '/' + PATHS.EMAIL_ID,
        element: <EmailDetail />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
