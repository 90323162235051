import { useMutation, useQueryClient } from 'react-query';
import { CreateForwardingEmailValues, createEmail } from '../../api';

export function useCreateForwardingEmail() {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: CreateForwardingEmailValues) => createEmail(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['emails'] });
      },
      onError: err => {
        console.error('Error creating forwarding email', err);
      },
    },
  );
}
