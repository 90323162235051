import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  useToast,
} from 'me-component-library';
import { Lock } from 'lucide-react';
import { PasswordForm } from '../../../../_shared/components/PasswordForm';
import { useUpdateEmailPassword } from '../../../hooks';
import { useTranslation } from '../../../../_shared/hooks';

type ChangePasswordDialogProps = {
  emailId: string;
};

export const ChangePasswordDialog = ({
  emailId,
}: ChangePasswordDialogProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toast } = useToast();
  const { mutateAsync, isError } = useUpdateEmailPassword();
  const t = useTranslation();

  const togglePasswordToast = () => {
    toast({
      title: t(
        'dashboard.sharedComponents.changePasswordDialog.toasts.success.title',
      ),
      description: t(
        'dashboard.sharedComponents.changePasswordDialog.toasts.success.message',
      ),
    });
  };

  const onPasswordSubmit = async (id: string, password: string) => {
    await mutateAsync(
      { emailId: id, password },
      {
        onSuccess: () => {},
      },
    );
    if (isError) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTrigger onClick={() => setIsModalOpen(true)} asChild>
        <div className="mt-3 text-sm text-indigo-500 font-bold cursor-pointer">
          {t('dashboard.sharedComponents.changePasswordDialog.title')}
        </div>
      </DialogTrigger>
      <DialogContent onCloseClick={() => setIsModalOpen(false)}>
        <DialogHeader>
          <DialogTitle>
            <div className="flex gap-6 items-center mb-3">
              <Lock />
              {t('dashboard.sharedComponents.changePasswordDialog.title')}
            </div>
          </DialogTitle>
          <DialogDescription>
            <span>
              {t('dashboard.sharedComponents.changePasswordDialog.description')}
            </span>
          </DialogDescription>
        </DialogHeader>
        <PasswordForm
          onSubmitAction={onPasswordSubmit}
          onSubmitSuccess={() => {
            togglePasswordToast();
            setIsModalOpen(false);
          }}
          passwordId={emailId}
        />

        <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
          {t('dashboard.sharedComponents.changePasswordDialog.cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
