import ky from 'ky';

const endpointPrefix = import.meta.env.VITE_API_URL || '';

export const api = ky.extend({
  prefixUrl: `${endpointPrefix}/api/v1`,
  hooks: {
    beforeRequest: [
      request => {
        const accessToken = localStorage.getItem('accessToken');
        request.headers.set('Authorization', `Bearer ${accessToken}`);
      },
    ],
  },
});
