export const violet = {
    '--background': '0 0% 100%',
    '--foreground': '224 71.4% 4.1%',
    '--card': '0 0% 100%',
    '--card-foreground': '224 71.4% 4.1%',
    '--popover': '0 0% 100%',
    '--popover-foreground': '224 71.4% 4.1%',
    '--primary': '262.1 83.3% 57.8%',
    '--primary-foreground': '210 20% 98%',
    '--secondary': '220 14.3% 95.9%',
    '--secondary-foreground': '220.9 39.3% 11%',
    '--muted': '220 14.3% 95.9%',
    '--muted-foreground': '220 8.9% 46.1%',
    '--accent': '220 14.3% 95.9%',
    '--accent-foreground': '220.9 39.3% 11%',
    '--destructive': '0 84.2% 60.2%',
    '--destructive-foreground': '210 20% 98%',
    '--border': '220 13% 91%',
    '--input': '220 13% 91%',
    '--ring': '262.1 83.3% 57.8%',
};
