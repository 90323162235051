import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'me-component-library';
import { Trash2 } from 'lucide-react';
import { useDeleteEmail } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../_shared/types';
import { useTranslation } from '../../../../_shared/hooks';

type EmailDeleteDialogProps = {
  emailId: string;
  emailAddress: string;
  title: string;
};

export const EmailDeleteDialog = ({
  emailId,
  emailAddress,
  title,
}: EmailDeleteDialogProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutateAsync, isLoading, isError } = useDeleteEmail();
  const navigate = useNavigate();
  const t = useTranslation();

  const handleDelete = async () => {
    await mutateAsync(emailId);
    if (isError) {
      return;
    }
    setIsModalOpen(false);
    navigate(`/${PATHS.DASHBOARD}/${PATHS.EMAILS}`);
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTrigger onClick={() => setIsModalOpen(true)} asChild>
        <div className="mt-3 text-sm text-red-500 font-bold cursor-pointer">
          {title}
        </div>
      </DialogTrigger>
      <DialogContent onCloseClick={() => setIsModalOpen(false)}>
        <DialogHeader>
          <DialogTitle>
            <div className="flex gap-6 items-center mb-3">
              <Trash2 /> {title}
            </div>
          </DialogTitle>
          <DialogDescription>
            <span>
              {t('dashboard.sharedComponents.emailDeleteDialog.title.part1')}
              <span className="font-bold">
                {t('dashboard.sharedComponents.emailDeleteDialog.title.part2', {
                  email: emailAddress,
                })}
              </span>
              {t('dashboard.sharedComponents.emailDeleteDialog.title.part3')}
            </span>
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-between">
          <Button
            variant="destructive"
            isLoading={isLoading}
            onClick={() => handleDelete()}
          >
            {t('dashboard.sharedComponents.emailDeleteDialog.button')}
          </Button>
          <Button onClick={() => setIsModalOpen(false)}>
            {t('dashboard.sharedComponents.emailDeleteDialog.cancel')}
          </Button>
        </div>
        {isError && (
          <span className="text-red-500">
            {t('dashboard.sharedComponents.emailDeleteDialog.error')}
          </span>
        )}
      </DialogContent>
    </Dialog>
  );
};
