import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from 'me-component-library';
import { useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useEffect } from 'react';
import { useTranslation } from '../../../_shared/hooks';

const formSchema = z.object({
  searchString: z.string().optional(),
});

export const EmailTableSearchBar: React.FC = () => {
  const [, setSearchParams] = useSearchParams();
  const t = useTranslation();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      searchString: '',
    },
  });
  const searchInput = useWatch({ control: form.control, name: 'searchString' });

  useEffect(() => {
    if (searchInput) {
      setSearchParams(`search=${searchInput}`);
    } else {
      setSearchParams('');
    }
  }, [searchInput, setSearchParams]);

  return (
    <div className="w-full md:!w-4/12">
      <Form {...form}>
        <FormField
          control={form.control}
          name="searchString"
          render={({ field }) => (
            <FormItem>
              <Input
                placeholder={t('dashboard.emailTable.searchBar.placeholder')}
                type="text"
                onKeyDown={e => {
                  if (e.key === 'Escape') {
                    form.reset();
                  }
                }}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </div>
  );
};
