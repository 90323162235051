var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
const ToggleSwitch = forwardRef((_a, ref) => {
    var { enabled, onChange, activeLabel, inactiveLabel } = _a, props = __rest(_a, ["enabled", "onChange", "activeLabel", "inactiveLabel"]);
    return (_jsx(_Fragment, { children: _jsxs("label", { className: "relative inline-flex items-center cursor-pointer", children: [_jsx("input", Object.assign({ type: "checkbox", className: "sr-only peer", checked: enabled, ref: ref, onChange: () => onChange(!enabled) }, props)), _jsx("div", { className: "w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" }), _jsx("span", { className: "ms-3 text-sm font-medium text-gray-900 dark:text-gray-300" }), enabled ? activeLabel : inactiveLabel] }) }));
});
ToggleSwitch.displayName = 'ToggleSwitch';
export { ToggleSwitch };
