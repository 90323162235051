import { Toaster } from 'me-component-library';
import { Navbar } from '../Navbar';
import { Outlet } from 'react-router-dom';
import { Footer, LanguageSelector } from '../../../_shared/components';
import { useCallback, useMemo } from 'react';
import { useGetCustomDomainData } from '../../../_shared/hooks/useGetCustomDomainData';
import { CustomDomainData } from '../../../_shared/types';
import { useTranslation } from '../../../_shared/hooks';
import React from 'react';

export const DashboardLayout: React.FC = () => {
  const { data } = useGetCustomDomainData();

  const t = useTranslation();
  const defaultTopBanner = '/default-top-banner.png';

  const renderedTopBannerSrc = useCallback((domaiData?: CustomDomainData) => {
    if (domaiData === null || domaiData === undefined) {
      return defaultTopBanner;
    } else if (domaiData.imgLogin.length === 0) {
      return defaultTopBanner;
    }

    return domaiData?.topImage;
  }, []);

  const renderedTopBanner = useMemo(
    () => renderedTopBannerSrc(data),
    [data, renderedTopBannerSrc],
  );

  const isDefaultTopBanner = renderedTopBanner === defaultTopBanner;

  return (
    <div className="min-h-screen bg-zinc-100 flex flex-col">
      <div className="h-64 bg-black flex items-center justify-between overflow-hidden">
        {isDefaultTopBanner ? (
          <>
            <div className="w-1/3"></div>
            <div className="w-1/3 flex justify-center relative">
              <div className="absolute inset-0 bg-gray-500 opacity-50 rounded-md mix-blend-multiply"></div>

              <img
                src={renderedTopBanner}
                alt="Top banner"
                className="max-h-full select-none"
              />
            </div>
            <div className="w-1/3 px-4">
              {renderedTopBanner === defaultTopBanner && (
                <div className="select-none">
                  <h2 className="text-3xl text-white font-semibold">
                    {t('dashboard.navbar.topBanner.title')}
                  </h2>
                  <p className="mt-2 text-neutral-400">
                    {t('dashboard.navbar.topBanner.subtitle')}
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="flex justify-center w-full h-full overflow-hidden p-1">
            <img
              src={renderedTopBanner}
              alt="Top banner"
              className="select-none"
            />
          </div>
        )}
        <div className="absolute top-0 right-0 m-5">
          <LanguageSelector />
        </div>
      </div>
      <div className="flex px-12 m-auto max-w-screen-2xl w-full flex-grow">
        <Navbar />
        <div className="p-12 w-full h-full">
          <Outlet />
        </div>
      </div>
      <Toaster />

      <Footer isFeedbacktourVisible={true} />
    </div>
  );
};
