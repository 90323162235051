import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { routes } from './routes';
import { useEffect } from 'react';
import { restoreCachedDomainData } from './_shared/utils';
import './_shared/i18n/config';

function App() {
  const queryClient = new QueryClient();
  const router = createBrowserRouter(routes);

  useEffect(() => {
    restoreCachedDomainData();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
