var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useState } from 'react';
import { cn } from '../../utils';
import { DicesIcon, EyeIcon, EyeOffIcon } from 'lucide-react';
const Input = forwardRef((_a, ref) => {
    var { className, type, showEyeIcon, showGeneratePasswordIcon, showGeneratePasswordCallback } = _a, props = __rest(_a, ["className", "type", "showEyeIcon", "showGeneratePasswordIcon", "showGeneratePasswordCallback"]);
    const [inputType, setInputType] = useState(type);
    const togglePasswordVisibility = useCallback(() => {
        setInputType(inputType === 'password' ? 'text' : 'password');
    }, [inputType]);
    return (_jsxs("span", { className: "flex items-center relative w-full", children: [_jsx("input", Object.assign({ type: inputType, className: cn('flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50', className), ref: ref }, props)), showEyeIcon && (_jsx("span", { className: "absolute right-0 text-sm select-none flex items-center mr-2 cursor-pointer", children: inputType === 'password' ? (_jsx(EyeIcon, { onClick: togglePasswordVisibility })) : (_jsx(EyeOffIcon, { onClick: togglePasswordVisibility })) })), showGeneratePasswordIcon && (_jsx("span", { className: "absolute right-0 text-sm select-none flex items-center mr-10 cursor-pointer", children: _jsx(DicesIcon, { onClick: () => showGeneratePasswordCallback !== undefined
                        ? showGeneratePasswordCallback()
                        : {} }) }))] }));
});
Input.displayName = 'Input';
export { Input };
