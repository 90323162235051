import { Button, Icon } from 'me-component-library';

type Props = {
  onClick: () => void;
};

export const EmailSelectButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={() => onClick()} variant="ghost" size="icon">
      <Icon name="chevronRight" />
    </Button>
  );
};
