import { useMutation, useQueryClient } from 'react-query';
import { UpdateCatchAllValues, updateCatchAll } from '../../api';

export function useUpdateCatchAll() {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: UpdateCatchAllValues) => updateCatchAll(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['emailDetails'] });
      },
      onError: err => {
        console.error('Error updating catch-all ', err);
      },
    },
  );
}
