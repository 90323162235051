import { ForgotPasswordForm } from '../../components/ForgotPasswordForm';
import { BasePage } from '../BasePage';

export const ForgotPassword: React.FC = () => {
  return (
    <BasePage>
      <ForgotPasswordForm />
    </BasePage>
  );
};
