import { useCallback } from 'react';
import './CheckBox.scss';

type CheckBoxProps = {
  checked: boolean;
  onChange: (value: boolean) => void;
  inputLabel: string;
};

export const CheckBox = ({ checked, onChange, inputLabel }: CheckBoxProps) => {
  const handleCheckBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      onChange(isChecked);
    },
    [onChange],
  );

  return (
    <>
      <label className="mw-check-box">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleCheckBoxChange}
        />
        <span className="ml-1">{inputLabel}</span>
      </label>
    </>
  );
};
