var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { cn } from '../../utils';
const Table = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", { className: "w-full overflow-auto", children: _jsx("table", Object.assign({ ref: ref, className: cn('w-full caption-bottom text-sm', className) }, props)) }));
});
Table.displayName = 'Table';
const TableHeader = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("thead", Object.assign({ ref: ref, className: cn('[&_tr]:border-b', className) }, props)));
});
TableHeader.displayName = 'TableHeader';
const TableBody = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("tbody", Object.assign({ ref: ref, className: cn('[&_tr:last-child]:border-0', className) }, props)));
});
TableBody.displayName = 'TableBody';
const TableFooter = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("tfoot", Object.assign({ ref: ref, className: cn('bg-slate-900 font-medium text-slate-50', className) }, props)));
});
TableFooter.displayName = 'TableFooter';
const TableRow = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("tr", Object.assign({ ref: ref, className: cn('border-b transition-colors hover:bg-slate-100/50 data-[state=selected]:bg-slate-100', className) }, props)));
});
TableRow.displayName = 'TableRow';
const TableHead = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("th", Object.assign({ ref: ref, className: cn('h-12 px-4 text-left align-middle font-medium text-slate-500 [&:has([role=checkbox])]:pr-0', className) }, props)));
});
TableHead.displayName = 'TableHead';
const TableCell = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("td", Object.assign({ ref: ref, className: cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className) }, props)));
});
TableCell.displayName = 'TableCell';
const TableCaption = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("caption", Object.assign({ ref: ref, className: cn('mt-4 text-sm text-slate-500', className) }, props)));
});
TableCaption.displayName = 'TableCaption';
export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption, };
