import { api } from './api';

export type UpdateSpamProtectionValues = {
  emailId: string;
  active: boolean;
  autoDeleteSpam: boolean;
  folder: string;
  relocationMinSpamScore: number;
};

export async function updateSpamProtection({
  emailId,
  ...values
}: UpdateSpamProtectionValues) {
  const response = await api.patch(`emails/${emailId}/spamprotection`, {
    json: {
      ...values,
    },
  });
  return response.json();
}
