import { useMutation, useQueryClient } from 'react-query';
import {
  UpdateAutoResponderValues,
  updateAutoResponder,
} from '../../api/updateAutoResponder';

export function useUpdateAutoResponder() {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: UpdateAutoResponderValues) => updateAutoResponder(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['emailDetails'] });
      },
      onError: err => {
        console.error('Error updating autoresponder', err);
      },
    },
  );
}
