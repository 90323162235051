import { useEffect } from 'react';

declare global {
  interface Window {
    projectId: string;
  }
}
export const Feedbacktour = () => {
  useEffect(() => {
    // const script = document.createElement('script');
    // script.src =
    //   'https://sdk.smart-feedback.digital/sdk.smart-feedback.digital/main.0.0.22.min.js';
    // script.async = true;
    // script.integrity =
    //   'sha384-OzcgqneAN6twZgHlXJ3XEZ/8vlkO26rKVwB3GKtA1ydvlxxyZRWsXq6Mv5gAOVEy';
    // script.crossOrigin = 'anonymous';
    // document.body.appendChild(script);
    // window.projectId = 'cluse7pn300018ytvo6jrsyt1';
    // return () => {
    //   document.body.removeChild(script);
    // };
    // TODO re-enable when ready
  }, []);
  return <div id="report-app"></div>;
};
