import { CheckCircle } from 'lucide-react';
import { EmailDetail } from '../../../../types';
import { useMemo } from 'react';
import { useTranslation } from '../../../../../_shared/hooks';

export type EmailEditTabAutoResponderBadgeProps = {
  expiresAt?: EmailDetail['autoResponder']['expiresAt'];
  timeFrameChecked: boolean;
};

export const EmailEditTabAutoResponderBadge: React.FC<
  EmailEditTabAutoResponderBadgeProps
> = ({ expiresAt, timeFrameChecked }) => {
  const t = useTranslation();
  const formattedDate = useMemo(() => {
    if (expiresAt !== undefined) {
      return new Date(expiresAt)
        .toLocaleDateString('en-GB')
        .replace(/\//g, '.');
    }
  }, [expiresAt]);

  if (timeFrameChecked === true && expiresAt === undefined) {
    return null;
  }

  return (
    <div className="mt-3 flex rounded-full bg-green-500 text-white p-1 text-sm items-center w-96">
      <span className="ml-2">
        <CheckCircle />
      </span>
      {expiresAt === undefined ? (
        <span className="ml-2">
          {t('dashboard.emailEditTabs.tabs.autoresponder.badge.variant1')}
        </span>
      ) : (
        <span className="ml-2">
          {t('dashboard.emailEditTabs.tabs.autoresponder.badge.variant2')}{' '}
          {formattedDate}
        </span>
      )}
    </div>
  );
};
