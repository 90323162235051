import { EmailEditTabGeneralCatchAll } from '..';
import { EmailDetail } from '../../../../types';
import { EmailDeleteDialog } from '../../..';
import { ChangePasswordDialog } from '../../../shared/ChangePasswordDialog';
import { EmailEditTabGeneraChangeAddressForm } from './EmailEditTabGeneraChangeAddressForm';
import { useTranslation } from '../../../../../_shared/hooks';

interface EmailEditTabGeneralProps {
  data: EmailDetail;
  isAdmin: boolean;
  hasMailbox: boolean;
}

export const EmailEditTabGeneral: React.FC<EmailEditTabGeneralProps> = ({
  data,
  isAdmin,
  hasMailbox,
}) => {
  const t = useTranslation();
  return (
    <>
      {isAdmin && (
        <>
          <div className="text-xl mb-3">
            {t('dashboard.emailEditTabs.tabs.general.common.title')}
          </div>
          <EmailEditTabGeneraChangeAddressForm
            emailId={data.id}
            currentEmail={data.address}
          />
          {hasMailbox && <hr className="my-9" />}
        </>
      )}
      {hasMailbox && (
        <>
          <div className="text-xl mb-3">
            {t('dashboard.emailEditTabs.tabs.general.common.password.title')}
          </div>
          <div className="text-sm mb-3">
            {t(
              'dashboard.emailEditTabs.tabs.general.common.password.description',
            )}
          </div>
          <ChangePasswordDialog emailId={data.id} />
          <div className="mt-4 text-sm">
            {t(
              'dashboard.emailEditTabs.tabs.general.common.password.lastChanged',
            )}
            {data.mailbox.passwordUpdatedAt
              ? new Date(data.mailbox.passwordUpdatedAt).toLocaleString()
              : t('dashboard.emailEditTabs.tabs.general.common.password.never')}
          </div>
        </>
      )}
      {isAdmin && (
        <>
          <hr className="my-9" />
          <EmailEditTabGeneralCatchAll
            emailId={data.id}
            isChecked={data.isCatchAll}
          />
        </>
      )}
      {isAdmin && (
        <>
          <hr className="my-9" />
          <div className="text-xl">
            {t('dashboard.emailEditTabs.tabs.general.common.options.title')}
          </div>
          <EmailDeleteDialog
            emailId={data.id}
            emailAddress={data.address}
            title={t(
              hasMailbox
                ? 'dashboard.emailEditTabs.tabs.general.common.options.delete.action'
                : 'dashboard.emailEditTabs.tabs.general.withoutInbox.delete.action',
            )}
          />
        </>
      )}
      <hr className="my-9" />
      <div className="text-sm">
        {t('dashboard.emailEditTabs.tabs.general.common.lastUpdated')}
        {data.updatedAt
          ? new Date(data.updatedAt).toLocaleString()
          : t('dashboard.emailEditTabs.tabs.general.common.password.never')}
      </div>
    </>
  );
};
