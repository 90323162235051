import { useCallback } from 'react';
import { UpdateSpamProtectionValues } from '../../../../api';
import { EmailDetail } from '../../../../types';
import { EmailEditTabSpamButton } from './EmailEditTabSpamButton';
import { useTranslation } from '../../../../../_shared/hooks';

const availableSpamSensivities: ['low' | 'medium' | 'high', number][] = [
  ['low', 4],
  ['medium', 7],
  ['high', 8],
];

type EmailEditTabSpamSensivityProps = {
  data: EmailDetail;
  raiseMutation: (newData: Partial<UpdateSpamProtectionValues>) => void;
};

export const EmailEditTabSpamSensivity = ({
  data,
  raiseMutation,
}: EmailEditTabSpamSensivityProps) => {
  const t = useTranslation();
  const renderButton = useCallback(
    ([text, value]: ['low' | 'medium' | 'high', number]) => {
      let active = value === data.mailbox.spamProtection.relocationMinSpamScore;

      if (
        data.mailbox.spamProtection.relocationMinSpamScore === 1 &&
        text === 'low'
      ) {
        active = true; // FIXME manual override because mittwald decorates new inboxes with score 1, first update in mstudio will put it to 4
      }

      return (
        <EmailEditTabSpamButton key={text} onClick={() => handleClick(value)}>
          <span className={active ? 'active' : ''}>
            {t(
              `dashboard.emailEditTabs.tabs.spam.sections.filter.actions.${text}`,
            )}
          </span>
        </EmailEditTabSpamButton>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const handleClick = useCallback(
    (value: number) => {
      raiseMutation({
        relocationMinSpamScore: value,
      });
    },
    [raiseMutation],
  );

  return (
    <>
      <div className="text-xl">
        {t('dashboard.emailEditTabs.tabs.spam.sections.filter.title')}
      </div>
      <div className="w-5/6 mt-2 text-sm">
        {t('dashboard.emailEditTabs.tabs.spam.sections.filter.description')}
      </div>
      <div className="mt-3 email-edit-tab-spam-grid">
        {availableSpamSensivities.map(([text, value]) =>
          renderButton([text, value]),
        )}
      </div>
    </>
  );
};
