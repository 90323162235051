import { api } from './api';

export type UpdateEmailPasswordValues = {
  emailId: string;
  password: string;
};

export async function updateEmailPassword({
  emailId,
  password,
}: UpdateEmailPasswordValues) {
  const response = await api.patch(`emails/${emailId}/password`, {
    json: {
      password,
    },
  });
  return response.json();
}
