import { forwardRef } from 'react';

type ToggleSwitchProps = {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  activeLabel: string;
  inactiveLabel: string;
};

const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
  ({ enabled, onChange, activeLabel, inactiveLabel, ...props }, ref) => {
    return (
      <>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={enabled}
            ref={ref}
            onChange={() => onChange(!enabled)}
            {...props}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
          {enabled ? activeLabel : inactiveLabel}
        </label>
      </>
    );
  },
);

ToggleSwitch.displayName = 'ToggleSwitch';
export { ToggleSwitch };
