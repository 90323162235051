export const generateRandomPassword = () => {
  const specialCharacters = '!@#$%^&*()_+{}|:"<>?';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const allCharacters = specialCharacters + lowercase + uppercase + numbers;
  let password = '';

  const hasCategory = (category: string) => {
    return password.split('').some(char => category.includes(char));
  };

  const generateCharacter = (category: string) => {
    const character = category.charAt(
      Math.floor(Math.random() * category.length),
    );
    if (!hasCategory(category)) {
      password += character;
    } else {
      generateCharacter(category);
    }
  };

  generateCharacter(specialCharacters);
  generateCharacter(lowercase);
  generateCharacter(uppercase);
  generateCharacter(numbers);

  for (let i = 0; i < 8; i++) {
    password += allCharacters.charAt(
      Math.floor(Math.random() * allCharacters.length),
    );
  }

  //shuffle password
  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return password;
};
