import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../types/paths';
import { useUser } from '../../../auth/hooks';
import { LoadingSpinner } from 'me-component-library';

export const PrivateRoutes: React.FC<PropsWithChildren> = ({ children }) => {
  const { data, isLoading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !data) {
      navigate(`/${PATHS.LOGIN}`);
    }
  }, [data, navigate, isLoading]);

  if (isLoading === true)
    return (
      <>
        <div className="w-full bg-gray-200 h-screen flex items-center justify-center">
          <LoadingSpinner />
        </div>
      </>
    );

  return <>{children}</>;
};
