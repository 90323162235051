export const changeFavicon = (url: string) => {
  document.head.querySelectorAll('link[rel="icon"]').forEach(favicon => {
    // Remove all favicons
    favicon.remove();
  });

  // Create a new link element with the provided favicon URL
  const newFaviconElement = document.createElement('link');
  newFaviconElement.rel = 'icon';
  newFaviconElement.type = 'image/x-icon';
  newFaviconElement.href = url;

  // Append the new link element to the head
  document.head.appendChild(newFaviconElement);
};
