import { Icon } from 'me-component-library';
import { NavTab } from '../Navbar/NavTab';
import { useLogout } from '../../../auth/hooks';

export const LogoutButton: React.FC = () => {
  const { mutate, isLoading } = useLogout();
  return (
    <NavTab
      tabTitle="dashboard.navbar.tabLabel.logout"
      onClick={mutate}
      icon={
        isLoading ? (
          <Icon name="loader" className="animate-spin" />
        ) : (
          <Icon name="logout" />
        )
      }
    />
  );
};
