import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useMemo, } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '../Icon';
import { cn } from '../../utils';
export const DropzoneContext = createContext({
    accept: false,
    idle: true,
    reject: false,
});
export const DropzoneAccept = ({ children, className, asChild, }) => {
    const { accept } = useContext(DropzoneContext);
    if (asChild) {
        return _jsx(_Fragment, { children: accept ? children : null });
    }
    return accept ? _jsx("div", { className: className, children: children }) : null;
};
export const DropzoneReject = ({ children, className, asChild, }) => {
    const { reject } = useContext(DropzoneContext);
    if (asChild) {
        return _jsx(_Fragment, { children: reject ? children : null });
    }
    return reject ? _jsx("div", { className: className, children: children }) : null;
};
export const DropzoneIdle = ({ children, className, asChild, }) => {
    const { idle } = useContext(DropzoneContext);
    if (asChild) {
        return _jsx(_Fragment, { children: idle ? children : null });
    }
    return idle ? _jsx("div", { className: className, children: children }) : null;
};
export const Dropzone = ({ className, children, isLoading, options, }) => {
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, } = useDropzone(options);
    const isIdle = useMemo(() => {
        return !isDragActive && !isDragAccept && !isDragReject;
    }, [isDragActive, isDragAccept, isDragReject]);
    const dropzoneStatus = useMemo(() => {
        if (isDragAccept) {
            return 'accept';
        }
        if (isDragReject) {
            return 'reject';
        }
        return 'idle';
    }, [isDragActive, isDragAccept, isDragReject]);
    return (_jsx(DropzoneContext.Provider, { value: { accept: isDragAccept, idle: isIdle, reject: isDragReject }, children: _jsxs("div", Object.assign({}, getRootProps(), { className: cn(className, 'relative'), "data-status": dropzoneStatus, children: [_jsx("input", Object.assign({}, getInputProps(), { "data-testid": "dropzone-input" })), isLoading && (_jsx("div", { className: "w-full h-full absolute top-0 left-0 bg-black/70 flex items-center justify-center", children: _jsx(Icon, { size: 60, name: "loader", className: "animate-spin stroke-blue-400" }) })), children] })) }));
};
