import { api } from '.';

export type RequestPasswordResetValues = {
  email: string;
  language: string;
};

export const requestPasswordReset = async ({
  email,
  language,
}: RequestPasswordResetValues) => {
  const response = await api.post('public/lost-password', {
    json: { email, language },
  });
  if (!response.ok) {
    throw new Error('Failed to request password reset');
  }
};
