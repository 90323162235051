import { api } from './api';

export type UpdateEmailAddressValues = {
  emailId: string;
  address: string;
};

export async function updateEmailAddress({
  emailId,
  address,
}: UpdateEmailAddressValues) {
  const response = await api.patch(`emails/${emailId}/address`, {
    json: {
      address,
    },
  });
  return response.status === 204;
}
