import { LoadingSpinner } from 'me-component-library';

export const LoadingOverlay = () => {
  /**
   * Returns a loading layover.
   *
   * @remarks
   * This needs a parent element with `position: relative` for the spinner to be positioned correctly.
   *
   */
  return (
    <>
      <div className="bg-slate-100 opacity-50 w-full h-full absolute z-10" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
        <LoadingSpinner />
      </div>
    </>
  );
};
