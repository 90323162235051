import { useCallback, useEffect, useState } from 'react';
import { UpdateAutoResponderValues } from '../../../../api/updateAutoResponder';
import { EmailDetail } from '../../../../types';
import { CheckBox } from '../../../shared';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './EmailEditTabAutoResponderTimeFrame.scss';
import { useTranslation } from '../../../../../_shared/hooks';

type EmailEditTabAutoResponderTimeFrameProps = {
  expiresAt?: EmailDetail['autoResponder']['expiresAt'];
  startsAt?: EmailDetail['autoResponder']['startsAt'];
  raiseMutation: (newData: Partial<UpdateAutoResponderValues>) => void;
  timeFrameChecked: boolean;
  raiseTimeFrameChecked: (checked: boolean) => void;
};

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const EmailEditTabAutoResponderTimeFrame: React.FC<
  EmailEditTabAutoResponderTimeFrameProps
> = ({
  timeFrameChecked,
  raiseTimeFrameChecked,
  raiseMutation,
  startsAt,
  expiresAt,
}) => {
  const [rangeValue, onChange] = useState<Value>([
    startsAt ? new Date(startsAt) : new Date(),
    expiresAt ? new Date(expiresAt) : new Date(),
  ]);
  const t = useTranslation();

  const handleDateChange = useCallback(
    (newDate: Value) => {
      const converted = newDate as [Date, Date];
      onChange(newDate);

      //null check
      if (converted === null) {
        raiseMutation({
          startsAt: undefined,
          expiresAt: undefined,
        });
      } else {
        raiseMutation({
          startsAt: converted[0].toISOString(),
          expiresAt: converted[1].toISOString(),
        });
      }
    },
    [raiseMutation],
  );

  useEffect(() => {
    if (timeFrameChecked === true) {
      handleDateChange(rangeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrameChecked]);

  return (
    <>
      <div className="text-xl mb-3">
        {t('dashboard.emailEditTabs.tabs.autoresponder.timeFrame.title')}
      </div>
      <div className="w-5/6 mt-3 text-sm">
        {t('dashboard.emailEditTabs.tabs.autoresponder.timeFrame.description')}
      </div>
      <div className="w-64 mt-3">
        <CheckBox
          checked={timeFrameChecked}
          onChange={raiseTimeFrameChecked}
          inputLabel={t(
            'dashboard.emailEditTabs.tabs.autoresponder.timeFrame.set',
          )}
        />
      </div>
      {timeFrameChecked && (
        <div className="mt-3">
          <DateRangePicker
            onChange={handleDateChange}
            value={rangeValue}
            returnValue="range"
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            minDate={new Date()}
          />
        </div>
      )}
    </>
  );
};
