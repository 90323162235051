import React, { ReactNode } from 'react';
import { Footer } from '../../../_shared/components';

interface WithFooterWrapperProps {
  children: ReactNode;
}

export const WithFooterWrapper: React.FC<WithFooterWrapperProps> = ({
  children,
}) => {
  return (
    <>
      {children}
      <Footer isFeedbacktourVisible={false} />
    </>
  );
};
