import { AtSign, X } from 'lucide-react';
import { LoadingOverlay } from '../../../shared/LoadingOverlay';

type EmailEditTabForwardingAddressLineProps = {
  address: string;
  isLoading: boolean;
  deleteEmail: (address: string) => void;
};

export const EmailEditTabForwardingAddressLine: React.FC<
  EmailEditTabForwardingAddressLineProps
> = ({ address, isLoading, deleteEmail }) => {
  return (
    <div className="flex justify-between email-tab-forwarding-address mt-2">
      <div className="flex">
        <span className="mx-3">
          <AtSign />
        </span>
        <span>{address}</span>
      </div>
      <div className="relative">
        {isLoading && <LoadingOverlay />}
        <span
          className="cursor-pointer p-3 text-indigo-500"
          onClick={() => deleteEmail(address)}
        >
          <X />
        </span>
      </div>
    </div>
  );
};
