import { useNavigate } from 'react-router-dom';
import { getProfile } from '../../api';
import { useQuery } from 'react-query';
import { PATHS } from '../../../_shared/types';

export function useUser() {
  const navigate = useNavigate();
  return useQuery('user', () => getProfile(), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      navigate(`/${PATHS.LOGIN}`);
    },
  });
}
