import { api } from './api';

export type CreateRegularEmailValues = {
  kind: string;
  address: string;
  isCatchAll: boolean;
  mailbox: {
    enableSpamProtection: boolean;
    password: string;
    quotaInBytes: number;
  };
};
export type CreateForwardingEmailValues = {
  kind: string;
  address: string;
  forwardAddresses: string[];
};

export async function createEmail({
  ...values
}: CreateRegularEmailValues | CreateForwardingEmailValues) {
  const response = await api.post(`emails`, {
    json: {
      ...values,
    },
  });

  return response.json();
}
