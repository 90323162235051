import { useMutation } from 'react-query';
import { UpdateEmailPasswordValues, updateEmailPassword } from '../../api';

export function useUpdateEmailPassword() {
  return useMutation(
    (variables: UpdateEmailPasswordValues) => updateEmailPassword(variables),
    {
      onError: err => {
        console.error('Error updating password ', err);
      },
    },
  );
}
