import { useMutation, useQueryClient } from 'react-query';
import {
  UpdateEmailAddressValues,
  updateEmailAddress,
} from '../../api/updateEmailAddress';

export function useUpdateEmailAddress() {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: UpdateEmailAddressValues) => updateEmailAddress(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['emailDetails'] });
        queryClient.invalidateQueries({ queryKey: ['emails'] });
      },
      onError: err => {
        console.error('Error updating email address', err);
      },
    },
  );
}
