import { LoginForm } from '../../components';
import { useUser } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../_shared/types';
import { useEffect } from 'react';
import { BasePage } from '../BasePage';
import { LoadingSpinner } from 'me-component-library';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useUser();

  useEffect(() => {
    if (data && !isError) {
      navigate('/' + PATHS.DASHBOARD + '/' + PATHS.EMAILS);
    }
  }, [data, navigate, isError]);

  if (isLoading === true) {
    return (
      <>
        <div className="w-full bg-gray-200 h-screen flex items-center justify-center">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <BasePage>
      <LoginForm />
    </BasePage>
  );
};
