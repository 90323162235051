import { useTranslation } from '../../hooks';
import { Feedbacktour } from '../Feedbacktour';

export const Footer = ({
  isFeedbacktourVisible,
}: {
  isFeedbacktourVisible: boolean;
}) => {
  const t = useTranslation();

  return (
    <>
      {isFeedbacktourVisible && (
        <div className="flex px-12 m-auto max-w-screen-2xl w-2/4 h-4/6 flex-grow relative z-20">
          <Feedbacktour />
        </div>
      )}

      <div className="overflow-hidden">
        <footer className="bg-gray-200 py-4 sticky bottom-0 flex justify-center">
          {t('footer.title')}
          <div className="ml-1 text-slate-600">
            <a href="mailto:support@weissaufschwarz.digital">
              {' '}
              support@weissaufschwarz.digital
            </a>{' '}
          </div>
        </footer>
        <footer className="bg-gray-900 inset-x-0 bottom-0 text-xs text-slate-400 px-4 py-3">
          <ul className="flex flex-row flex-nowrap md:justify-start gap-5 md:gap-8 items-center">
            <li>
              <a
                href="https://weissaufschwarz.digital/imprint"
                target="_blank"
                rel="noopener norefferer"
              >
                {t('footer.imprint')}
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
};
