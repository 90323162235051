import { api } from './api';
import { Email } from '../types/email';

export async function getEmails(): Promise<Email[]> {
  const response = await api.get(`emails?include=mailbox,forwarded`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}
