import { useQuery } from 'react-query';
import { getEmails } from '../../api';

type useGetEmailsOptions = {
  fromCache?: boolean;
};

export function useGetEmails({ fromCache = false }: useGetEmailsOptions = {}) {
  return useQuery('emails', () => getEmails(), {
    refetchOnWindowFocus: false,
    staleTime: fromCache ? 1000 * 60 * 5 : undefined, //5 minutes or infinity = don't cache
  });
}
