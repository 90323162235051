import { useQuery } from 'react-query';
import { getCustomDomainData } from '../../api';
import { changeTheme } from 'design-tokens';
import {
  getCachedDomainData,
  setCachedDomainData,
  changeDocumentTitle,
  changeFavicon,
} from '../../utils';
import i18next from 'i18next';

export function useGetCustomDomainData({ fromCache = true } = {}) {
  return useQuery('domainData', () => getCustomDomainData(), {
    onSuccess: data => {
      if (!data) return;

      const cachedDomainData = getCachedDomainData();

      if (data.theme !== cachedDomainData?.theme) {
        changeTheme(data.theme);
      }

      if (data.name !== cachedDomainData?.name) {
        changeDocumentTitle(data.name);
      }

      if (data.favicon !== cachedDomainData?.favicon) {
        changeFavicon(data.favicon === '' ? '/default.png' : data.favicon);
      }

      if (data.language !== cachedDomainData?.language) {
        i18next.changeLanguage(data.language);
      }

      setCachedDomainData(data);
    },
    initialData: getCachedDomainData() || undefined,
    staleTime: fromCache ? 1000 : undefined, // 1 second or infinity = don't cache
    retry: false,
  });
}
