import { Forward } from 'lucide-react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'me-component-library';
import { useState } from 'react';
import { EmailEditTabForwardingForm } from '../EmailEditDetails/EmailEditTabs/EmailEditTabForwarding/EmailEditTabForwardingForm';
import { EmailEditTabForwardingAddressLine } from '../EmailEditDetails/EmailEditTabs/EmailEditTabForwarding/EmailEditTabForwardingAddressLine';
import './shared/EmailAddDialogs.scss';
import { EmailAddDialogAddressForm } from './shared/EmailAddDialogAddressForm';
import { useCreateForwardingEmail } from '../../hooks/useCreateForwardingEmail';
import { useTranslation } from '../../../_shared/hooks';

export const EmailAddForwardingAddress = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forwardingTargets, setForwardingTargets] = useState<string[]>([]);
  const [address, setAddress] = useState<string>('');
  const t = useTranslation();
  const { mutateAsync, isLoading, isError } = useCreateForwardingEmail();

  const addForwardingTarget = (target: string) => {
    setForwardingTargets([...forwardingTargets, target]);
  };

  const removeForwardingTarget = (target: string) => {
    setForwardingTargets(forwardingTargets.filter(t => t !== target));
  };

  const handleAddressBlur = (address: string) => {
    setAddress(address);
  };

  const handleSubmitAndOptionallyAddAnother = async (addAnother: boolean) => {
    await mutateAsync({
      address,
      forwardAddresses: forwardingTargets,
      kind: 'FORWARDED_EMAIL',
    });

    if (isError) return;

    if (addAnother === true) {
      setAddress('');
      setForwardingTargets([]);
    } else {
      setIsModalOpen(false);
    }
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTrigger onClick={() => setIsModalOpen(true)} asChild>
        <div className="flex items-center text-sm text-white font-bold cursor-pointer bg-black rounded p-3">
          <Forward />
          <span className="ml-3">
            {t('dashboard.emailAddDialogs.forwarding.title')}
          </span>
        </div>
      </DialogTrigger>
      <DialogContent
        onCloseClick={() => setIsModalOpen(false)}
        className="email-add-dialogs"
      >
        <DialogHeader>
          <DialogTitle>
            <div className="flex">
              <Forward />
              <span className="ml-3">
                {t('dashboard.emailAddDialogs.forwarding.title')}
              </span>
            </div>
          </DialogTitle>
          <DialogDescription>
            <span className="w-5/6">
              {t('dashboard.emailAddDialogs.forwarding.description')}
            </span>
          </DialogDescription>
          <hr className="my-9" />
        </DialogHeader>
        <div className="w-7/12">
          <EmailAddDialogAddressForm
            raiseAddress={handleAddressBlur}
            address={address}
          />
        </div>
        <EmailEditTabForwardingForm
          isLoading={false}
          addEmail={addForwardingTarget}
          existingEmails={forwardingTargets}
        />
        {forwardingTargets.map(target => (
          <EmailEditTabForwardingAddressLine
            key={target}
            address={target}
            isLoading={false}
            deleteEmail={removeForwardingTarget}
          />
        ))}
        <div className="flex justify-between">
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            {t('dashboard.emailAddDialogs.buttons.cancel')}
          </Button>
          <div className="flex gap-4">
            <Button
              variant="outline"
              onClick={() => handleSubmitAndOptionallyAddAnother(true)}
              isLoading={isLoading}
              disabled={forwardingTargets.length === 0}
            >
              {t('dashboard.emailAddDialogs.buttons.createAndAddAnother')}
            </Button>
            <Button
              variant="default"
              onClick={() => handleSubmitAndOptionallyAddAnother(false)}
              isLoading={isLoading}
              disabled={forwardingTargets.length === 0 || address.length === 0}
            >
              {t('dashboard.emailAddDialogs.buttons.create')}
            </Button>
          </div>
        </div>
        {isError && (
          <div className="text-red-500 font-bold text-right">
            {t('dashboard.emailDetails.details.errors.generic')}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
