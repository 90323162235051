import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from './en';
import { de } from './de';

export const defaultNS = 'translation';

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en,
    de,
  },
  fallbackLng: 'en',
  defaultNS,
});

export default i18next;
