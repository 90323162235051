import { useTranslation } from '../../../_shared/hooks';
import { TranslationKey } from '../../../_shared/types/i18n';

type Props = {
  tabTitle: TranslationKey;
  icon: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
};

export const NavTab: React.FC<Props> = ({
  tabTitle,
  onClick,
  icon,
  isActive,
}) => {
  const t = useTranslation();
  return (
    <button
      className={`flex w-full py-3 px-4 items-center gap-2 rounded-md transition-all ease-in-out duration-200 cursor-pointer text-background-foreground ${
        isActive
          ? 'bg-primary/90 text-primary-foreground'
          : 'hover:bg-primary/20'
      }`}
      onClick={onClick}
    >
      {icon}
      <span>{t(tabTitle)}</span>
    </button>
  );
};
