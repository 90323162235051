import { EmailAddForwardingAddress, EmailAddInboxAddress } from '..';

export const EmailAddButtons = () => {
  return (
    <div className="flex gap-5">
      <EmailAddForwardingAddress />
      <EmailAddInboxAddress />
    </div>
  );
};
