var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '../../utils';
import { Icon } from '../Icon';
export const Button = React.forwardRef((_a, ref) => {
    var { className, variant, size, asChild = false, isLoading, children } = _a, props = __rest(_a, ["className", "variant", "size", "asChild", "isLoading", "children"]);
    const selectedVariant = buttonVariants[variant || 'default'];
    const selectedSize = buttonSizes[size || 'default'];
    const selectedChildren = isLoading ? (_jsx(Icon, { name: "loader", className: "animate-spin" })) : (children);
    const Comp = asChild ? Slot : 'button';
    return (_jsx(Comp, Object.assign({ className: cn('inline-flex items-center align-center justify-center rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50', selectedVariant, selectedSize, className), ref: ref }, props, { children: selectedChildren })));
});
Button.displayName = 'Button';
const buttonVariants = {
    default: 'bg-primary text-primary-foreground hover:bg-primary/90',
    destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
    outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
    secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
    ghost: 'hover:bg-accent hover:text-accent-foreground',
    link: 'text-primary underline-offset-4 hover:underline',
};
const buttonSizes = {
    default: 'h-10 px-4 py-2',
    sm: 'h-9 rounded-md px-3',
    lg: 'h-11 rounded-md px-8',
    icon: 'h-10 w-10',
};
