import { Forward, Mail } from 'lucide-react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'me-component-library';
import { useMemo, useState } from 'react';
import { useTranslation } from '../../../_shared/hooks';
import { EmailAddDialogAddressForm } from './shared/EmailAddDialogAddressForm';
import { CatchAllCheckBox, CheckBox } from '../shared';
import { EmailEditTabStorageQuotaForm } from '../EmailEditDetails/EmailEditTabs/EmailEditTabStorage/EmailEditTabStorageQuotaForm';
import { PasswordForm } from '../../../_shared/components/PasswordForm';
import { useCreateRegularEmail } from '../../hooks/useCreateRegularEmail';

export const EmailAddInboxAddress = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [address, setAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [selectedQuota, setSelectedQuota] = useState<string>('');
  const [isCatchAllChecked, setIsCatchAllChecked] = useState(false);
  const [isAntiSpamChecked, setIsAntiSpamChecked] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const t = useTranslation();
  const { mutateAsync, isLoading, isError } = useCreateRegularEmail();

  const handleAddressBlur = (address: string) => {
    setAddress(address);
  };

  const handlePasswordBlur = (raisedPassword: string) => {
    setPassword(raisedPassword);
  };

  const handleSubmitAndOptionallyAddAnother = async (addAnother: boolean) => {
    const quotaInBytes =
      parseFloat(selectedQuota.replace(',', '.')) * 1024 * 1024 * 1024;

    await mutateAsync({
      address,
      kind: 'REGULAR_EMAIL',
      isCatchAll: isCatchAllChecked,
      mailbox: {
        password,
        quotaInBytes,
        enableSpamProtection: isAntiSpamChecked,
      },
    });

    if (isError) return;

    if (addAnother === true) {
      setAddress('');
      setResetKey(prevKey => prevKey + 1);
      setIsCatchAllChecked(false);
      setIsAntiSpamChecked(false);
    } else {
      setIsModalOpen(false);
    }
  };

  const isValidFormState = useMemo(() => {
    return (
      address.length > 0 && password.length > 0 && selectedQuota.length > 0
    );
  }, [selectedQuota, address, password]);

  return (
    <Dialog open={isModalOpen}>
      <DialogTrigger onClick={() => setIsModalOpen(true)} asChild>
        <div className="flex items-center text-sm text-white font-bold cursor-pointer bg-black rounded p-3">
          <Mail />
          <span className="ml-3">
            {t('dashboard.emailAddDialogs.inbox.title')}
          </span>
        </div>
      </DialogTrigger>
      <DialogContent
        onCloseClick={() => setIsModalOpen(false)}
        className="email-add-dialogs"
      >
        <DialogHeader>
          <DialogTitle>
            <div className="flex">
              <Forward />
              <span className="ml-3">
                {t('dashboard.emailAddDialogs.inbox.title')}
              </span>
            </div>
          </DialogTitle>
          <DialogDescription>
            <span className="w-5/6">
              {t('dashboard.emailAddDialogs.inbox.description')}
            </span>
          </DialogDescription>
          <hr className="my-9" />
        </DialogHeader>
        <div className="flex justify-between gap-9">
          <div className="w-1/2">
            <EmailAddDialogAddressForm
              raiseAddress={handleAddressBlur}
              address={address}
            />
          </div>
          <div className="w-1/2">
            <PasswordForm
              key={`passwordForm-${resetKey}`}
              hideSubmitButton={true}
              hideConfirmPassword={true}
              hideStrengthDisplay={true}
              onSubmitSuccess={() => {}}
              onSubmitAction={async () => false}
              passwordId="password"
              raiseAddress={handlePasswordBlur}
            />
          </div>
        </div>
        <hr />
        <div className="text-xl">
          {t('dashboard.emailDetails.tabs.storage')}
        </div>
        <p className="mt-2 text-sm w-5/6">
          {t('dashboard.emailEditTabs.tabs.storage.sections.quota.description')}
        </p>
        <EmailEditTabStorageQuotaForm
          key={`emailEdit-${resetKey}`}
          raiseSubmit={async (quota: string) => {
            setSelectedQuota(quota);
          }}
          quotaLimitInGB=""
          isLoading={false}
          showSubmitButton={false}
        />
        <hr />
        <div className="text-xl">
          {t('dashboard.emailAddDialogs.inbox.additionalSettings.title')}
        </div>
        <div className="flex gap-9 items-center">
          <CheckBox
            checked={isAntiSpamChecked}
            onChange={setIsAntiSpamChecked}
            inputLabel={t(
              'dashboard.emailAddDialogs.inbox.additionalSettings.activateAntiSpam',
            )}
          />
          <div className="relative">
            <CatchAllCheckBox
              isCatchAllChecked={isCatchAllChecked}
              setIsCatchAllChecked={setIsCatchAllChecked}
              inputLabel={t(
                'dashboard.emailEditTabs.tabs.general.withInbox.catchAll.action',
              )}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            {t('dashboard.emailAddDialogs.buttons.cancel')}
          </Button>
          <div className="flex gap-4">
            <Button
              variant="outline"
              onClick={() => handleSubmitAndOptionallyAddAnother(true)}
              isLoading={isLoading}
              disabled={!isValidFormState}
            >
              {t('dashboard.emailAddDialogs.buttons.createAndAddAnother')}
            </Button>
            <Button
              variant="default"
              onClick={() => handleSubmitAndOptionallyAddAnother(false)}
              isLoading={isLoading}
              disabled={!isValidFormState}
            >
              {t('dashboard.emailAddDialogs.buttons.create')}
            </Button>
          </div>
        </div>
        {isError && (
          <div className="text-red-500 font-bold text-right">
            {t('dashboard.emailDetails.details.errors.generic')}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
