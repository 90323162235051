import { useToast } from 'me-component-library';
import { useUpdateCatchAll } from '../../../../hooks/useUpdateCatchAll';
import { LoadingOverlay } from '../../../shared/LoadingOverlay';
import { useEffect, useState } from 'react';
import { CatchAllCheckBox, UpdateOrCancelButtons } from '../../../shared';
import { useTranslation } from '../../../../../_shared/hooks';

type EmailEditTabGeneralCatchAllProps = {
  emailId: string;
  isChecked: boolean;
};

export const EmailEditTabGeneralCatchAll = ({
  emailId,
  isChecked: isCheckedInDetails,
}: EmailEditTabGeneralCatchAllProps) => {
  const { toast } = useToast();
  const { isLoading, mutate } = useUpdateCatchAll();
  const [isCatchAllChecked, setIsCatchAllChecked] =
    useState(isCheckedInDetails);
  const [showSubmitButtons, setShowSubmitButtons] = useState(false);
  const t = useTranslation();

  const toggleErrorToast = () => {
    toast({
      title: t('dashboard.emailEditTabs.tabs.shared.toasts.error.title'),
      description: t(
        'dashboard.emailEditTabs.tabs.shared.toasts.error.message',
      ),
      variant: 'error',
      duration: 3000,
    });
  };

  const toggleSuccessToast = () => {
    toast({
      title: t(
        'dashboard.emailEditTabs.tabs.general.withInbox.catchAll.toasts.success.title',
      ),
      description: t(
        'dashboard.emailEditTabs.tabs.general.withInbox.catchAll.toasts.success.message',
      ),
      variant: 'success',
      duration: 3000,
    });
  };

  useEffect(() => {
    setShowSubmitButtons(isCatchAllChecked !== isCheckedInDetails);
  }, [isCatchAllChecked, isCheckedInDetails]);

  const handleCheckboxChange = (isChecked: boolean) => {
    mutate(
      {
        emailId,
        catchAll: isChecked,
      },
      {
        onSuccess: () => {
          toggleSuccessToast();
        },
        onError: () => {
          toggleErrorToast();
        },
      },
    );
  };

  return (
    <>
      <div className="text-xl">
        {t('dashboard.emailEditTabs.tabs.general.withInbox.catchAll.title')}
      </div>
      <p className="mt-2 mb-2 text-sm w-5/6">
        {t(
          'dashboard.emailEditTabs.tabs.general.withInbox.catchAll.description',
        )}
      </p>
      <div className="relative w-64 mt-3">
        {isLoading && <LoadingOverlay />}
        <CatchAllCheckBox
          isCatchAllChecked={isCatchAllChecked}
          setIsCatchAllChecked={setIsCatchAllChecked}
          inputLabel={t(
            'dashboard.emailEditTabs.tabs.general.withInbox.catchAll.action',
          )}
          emailId={emailId}
        />
        {showSubmitButtons && (
          <UpdateOrCancelButtons
            onCancel={() => setIsCatchAllChecked(isCheckedInDetails)}
            onUpdate={() => handleCheckboxChange(isCatchAllChecked)}
          />
        )}
      </div>
    </>
  );
};
