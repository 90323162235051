import { api } from './api';

export type UpdateCatchAllValues = {
  emailId: string;
  catchAll: boolean;
};

export const updateCatchAll = async ({
  emailId,
  catchAll,
}: UpdateCatchAllValues) => {
  const response = await api.patch(`emails/${emailId}/catch-all`, {
    json: {
      active: catchAll,
    },
  });
  return response.json();
};
