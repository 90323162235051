import { LanguageSelector } from '../../_shared/components';
import { LoginCover, CompanyLogo } from '../components';
import { PropsWithChildren } from 'react';
import { useGetCustomDomainData } from '../../_shared/hooks/useGetCustomDomainData';
import { LoadingSpinner, Toaster } from 'me-component-library';

export const BasePage: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoading: isCustomDataLoading } = useGetCustomDomainData();

  if (isCustomDataLoading === true) {
    return (
      <>
        <div className="w-full bg-gray-200 h-screen flex items-center justify-center">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col md:!flex-row min-h-screen w-full relative">
      <div className="flex flex-1 flex-col gap-4 bg-zinc-100 justify-center">
        <div className="flex justify-end p-5">
          <LanguageSelector />
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <CompanyLogo />
          {children}
        </div>
      </div>
      <LoginCover />
      <Toaster />
    </div>
  );
};
