import { useCallback } from 'react';
import { UpdateSpamProtectionValues } from '../../../../api';
import { EmailDetail } from '../../../../types';
import { CheckBox } from '../../../shared';
import { useTranslation } from '../../../../../_shared/hooks';

type EmailEditTabSpamAutoDeleteProps = {
  data: EmailDetail;
  raiseMutation: (newData: Partial<UpdateSpamProtectionValues>) => void;
};

export const EmailEditTabSpamAutoDelete = ({
  data,
  raiseMutation,
}: EmailEditTabSpamAutoDeleteProps) => {
  const t = useTranslation();
  const handleClick = useCallback(
    (value: boolean) => {
      raiseMutation({
        autoDeleteSpam: value,
      });
    },
    [raiseMutation],
  );

  return (
    <>
      <div className="text-xl">
        {t('dashboard.emailEditTabs.tabs.spam.sections.autodelete.title')}
      </div>
      <div className="w-5/6 mt-2 text-sm">
        {t('dashboard.emailEditTabs.tabs.spam.sections.autodelete.description')}
      </div>
      <div className="mt-2 w-72">
        <CheckBox
          inputLabel={t(
            'dashboard.emailEditTabs.tabs.spam.sections.autodelete.action',
          )}
          onChange={handleClick}
          checked={data.mailbox.spamProtection.autoDeleteSpam}
        />
      </div>
    </>
  );
};
